.stars {
    color: #ddd;
    font-size: 24px;
  }
  
  .star {
    display: inline-block;
    cursor: pointer;
  }
  
  .star.active {
    color: gold;
  }